import { Tab, TabProps, Tabs } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { colors } from '../../../tailwind-design-preset';

/**
 * @interface TabItem
 * @property {string} label is the text that will be displayed in the tab
 * @property {string} value is the value that will be used in the url
 * @property {ReactNode} node is the content that will be displayed when the tab is active
 */
export interface TabItem<T> {
  label: string;
  value: T;
  node: ReactNode;
}

interface NoddiTabsProps<T> extends Omit<TabProps, 'action' | 'defaultValue'> {
  tabItems: TabItem<T>[];
  defaultValue: T;
}

export function NoddiTabs<T>({ tabItems, defaultValue, onChange, sx, ...rest }: NoddiTabsProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get('tab');
  const validTabValues = tabItems.map((item) => item.value);
  const currentTab = validTabValues.includes(tab as T) ? (tab as string) : defaultValue;

  useEffect(() => {
    if (!tab) {
      searchParams.set('tab', currentTab as string);
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={(_e, value) => {
          searchParams.set('tab', value);
          setSearchParams(searchParams);
          onChange?.(value);
        }}
        TabIndicatorProps={{
          style: {
            background: colors.primary.purple,
            color: colors.primary.purple
          }
        }}
        sx={{
          marginBottom: '2rem',
          '& .MuiButtonBase-root': {
            '&.MuiTab-root': {
              '&.Mui-selected': {
                color: colors.primary.purple
              }
            }
          },
          ...sx
        }}
        {...rest}
      >
        {tabItems.map((item) => (
          <Tab key={item?.value as string} label={item.label} value={item.value} />
        ))}
      </Tabs>

      {tabItems.find((item) => item.value === currentTab)?.node || null}
    </>
  );
}
