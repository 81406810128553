import { ThemeProvider } from '@mui/material/styles';
import { noddiAsync } from 'noddi-async';
import { AuthProvider, NoddiLocalizationProvider, useAuthContext } from 'noddi-provider';
import { APIBanner, ErrorPage, NoddiCircularLoader, ToastProvider, createTheme } from 'noddi-ui';
import queryString from 'query-string';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import Account from './Pages/Account';
import { GlobalPickingList } from './Pages/GlobalPickingList';
import Login from './Pages/Login/Login';
import { PrintLabelWrapper } from './Pages/PrintLabels';
import { PrintLabelsByLicensePlateNumber } from './Pages/PrintLabelsByLicensePlateNumber';
import StorageUnits from './Pages/StorageUnits';
import routes from './appRoutes';
import HomePrivateLayout from './layout/HomePrivateLayout';

function App() {
  const theme = createTheme();

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify
        }}
      >
        <NoddiServerContext>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <NoddiLocalizationProvider>
                  <Router />
                  <APIBanner />
                </NoddiLocalizationProvider>
              </ToastProvider>
            </ThemeProvider>
          </AuthProvider>
        </NoddiServerContext>
      </QueryParamProvider>
    </ErrorBoundary>
  );
}

const Router = () => {
  const { isTokenLoginLoading } = useAuthContext();

  if (isTokenLoginLoading) {
    return (
      <div className='flex min-h-screen justify-center bg-pint-to-lighterPint-gradient'>
        <NoddiCircularLoader />
      </div>
    );
  }
  return (
    <div className='h-full'>
      <Routes>
        <Route path={routes.login.getBasePath()} element={<Login />} />
        <Route element={<HomePrivateLayout />}>
          <Route path='/' element={<PrintLabelWrapper />} />
          <Route
            path={routes.printLabelsLicensePlateNumber.getBasePath()}
            element={<PrintLabelsByLicensePlateNumber />}
          />
          <Route path={routes.storageUnits.getBasePath()} element={<StorageUnits />} />
          <Route path={routes.globalPickingList.getBasePath()} element={<GlobalPickingList />} />
          <Route path={routes.profile.getBasePath()} element={<Account />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
